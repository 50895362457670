import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_blueprint-do.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PressRelease from '../components/PressReleaseFaq/PressRelease';
import Faqs from '../components/PressReleaseFaq/Faqs';
import * as globalAnimation from '../animations/globalAnimation';

export default class PressReleaseFaq extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 'Press Release',
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.fadeUp(this.tl, '.animate-fade-up');
  }

  render() {
    const { activePage } = this.state;
    const PressReleaseclick = () => {
      this.setState({
        activePage: 'Press Release',
      });
    };

    const faqsClick = () => {
      this.setState({
        activePage: 'Frequently Asked Questions',
      });
    };

    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle="Press Release and FAQs" />

        <Container fluid="true">
          <Row>
            <Col xs={12} lg={12}>
              <div className="main_wrapper">
                <div className="wrapper working-with">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">
                      Trinity Life Sciences acquires BluePrint Research Group
                    </h3>
                    <h4 className="text-uppercase page_heading_child subtitle">
                      Trinity Life Sciences acquires BluePrint Research Group
                    </h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mb-sm-3 mb-lg-4">
            <Col xs={10} lg={3}>
              <a onClick={PressReleaseclick}>
                <div
                  className={`${
                    activePage === 'Press Release' ? 'button-select' : 'button-non-select'
                  } p-2 p-lg-3`}
                >
                  <h5>Press Release</h5>
                </div>
              </a>
              {activePage === 'Press Release' ? (
                <div className="blue_line">
                  <hr className="mb-4 mb-lg-0" />
                </div>
              ) : null}
            </Col>
            <Col xs={10} lg={3}>
              <a onClick={faqsClick}>
                <div
                  className={`${
                    activePage === 'Frequently Asked Questions'
                      ? 'button-select'
                      : 'button-non-select'
                  } p-2 p-lg-3`}
                >
                  <h5>
                    Frequently Asked <br /> Questions
                  </h5>
                </div>
              </a>
              {activePage === 'Frequently Asked Questions' ? (
                <div className="blue_line">
                  <hr className="mb-4 mb-lg-0" />
                </div>
              ) : (
                <div className="mb-4" />
              )}
            </Col>
          </Row>
          {activePage === 'Frequently Asked Questions' ? <Faqs /> : <PressRelease />}
        </Container>
      </Layout>
    );
  }
}
