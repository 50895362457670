import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Trinity from '../../../static/img/faq/Trinity.png';
import BPRG from '../../../static/img/faq/bprg-logo.png';
import './pressReleaseStyle.scss';

function Faqs() {
  return (
    <section className="expect-associate">
      <Row className="justify-content-around pb-3 animate-fade-up-blocks">
        <Col xs={12} lg={10}>
          <p className="text-appcolor faq_page_title text-semibold">Frequently Asked Questions</p>

          <div className="circle-appcolor-bullets">
            <p className="text-bold">Why did Trinity acquire BluePrint Research Group?</p>
            <ul>
              <li>
                The acquisition of BluePrint Research Group makes Trinity the leading provider of
                premier, commercially focused strategy, insights and analytics for the life sciences
                industry. This increases Trinity&apos;s capacity to serve more and new clients and
                addresses the Company&apos;s growing demand from current clients. It also solidifies
                its global presence with an additional office in London, and significantly increases
                its current presence in India and both the East and West Coast of the U.S.
                <br />
                <div className="my-3">
                  <img
                    src={Trinity}
                    alt=""
                    className="img-fluid mr-5 mb-3"
                    style={{ width: 'auto', height: '60px' }}
                  />
                  <img
                    src={BPRG}
                    alt=""
                    className="img-fluid mb-3"
                    style={{ width: 'auto', height: '60px' }}
                  />
                </div>
              </li>
            </ul>

            <p className="text-bold">When did the acquisition take place?</p>
            <ul>
              <li>
                Trinity announced the acquisition of BluePrint Research Group on December 8, 2020.
              </li>
            </ul>

            <p className="text-bold">Where is BluePrint Research Group located?</p>
            <ul>
              <li>BluePrint Research Group has locations all over the world:</li>
              <div className="circle-dark-bullets-small">
                <ul>
                  <li>Princeton, NJ</li>
                  <li>New York, NY</li>
                  <li>London, UK</li>
                  <li>San Francisco, CA</li>
                  <li>Cambridge, MA</li>
                  <li>Philadelphia, PA</li>
                </ul>
              </div>
            </ul>
            <p className="text-bold">
              How does this acquisition fit into Trinity’s long-term strategy?
            </p>
            <ul>
              <li>
                The addition of 100 highly experienced consultants from BluePrint Research Group to
                the current Trinity Client Services team allows Trinity to further expand its
                influence and impact with clients and the life sciences industry. The competitive
                advantage of being the leading provider of commercially focused strategy, insights
                and analytics for the life sciences industry allows Trinity to scale faster and
                offer even more resources to its existing and future client base.
              </li>
            </ul>

            <p className="text-bold">Is there culture alignment between the two companies?</p>
            <ul>
              <li>
                Yes. The aligned cultures and values of both companies reflect a best-in-class,
                evidence-first approach to helping clients make critical business decisions. Pro
                bono, DEI initiatives and volunteer opportunities will continue to offer employees
                chances to make a tangible, visible impact within the life sciences industry and
                within the two companies’ respective communities.
              </li>
            </ul>

            <p className="text-bold">
              How will clients of both companies benefit from the acquisition?
            </p>
            <ul>
              <li>
                Client needs will be a priority during the acquisition and integration process.
                Trinity has a thoughtful integration plan in place to ensure there is no disruption
                in services to clients.
              </li>
              <li>
                BluePrint Research Group clients will benefit from an expanded portfolio of
                capabilities. Trinity clients will benefit from the additional resources allowing
                the Company to serve more and new clients and address the Company&apos;s growing
                demand from current clients.
              </li>
            </ul>

            <p className="text-bold">Who are BluePrint Research Group and Trinity clients?</p>
            <ul>
              <li>
                Once combined, Trinity clients include the Top 20 pharmaceutical companies* as well
                as the world’s top life science biotech and medical device companies.
              </li>
            </ul>
          </div>
          <div>
            <p className="text-medium">
              *Based on the{' '}
              <a href="https://www.rankingthebrands.com/The-Brand-Rankings.aspx?rankingID=370">
                Pharmaceutical Executive Top 50 Global Companies 2019.
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default Faqs;
